import React, { useState, useContext, useEffect, useRef } from 'react'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'
import linkIcon from '../images/link.png'

import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { ContextLoader } from '../context';

import { graphql } from "gatsby"
import Picture from '../components/Picture'

const Shapes = ({ data }) => {
  const [isLoading] = useContext(ContextLoader);
  useEffect(() => {
      if (isLoading === true) return
      const [observers, elements] = detectElementsOnScreen()
      return () => {
          killObservers(observers, elements)
      }
  }, [isLoading])
  
	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.allPrismicShapes.edges[0].node.data

    const upperShapes = pageData.upper_shapes
    const lowerShapes = pageData.lower_shapes

    const lowerSection = <div className='Shapes__Section'>
    <div className='Shapes__Section_TextRight'>
        <h2 className='Shapes__Section_Subheading'>{pageData.lower_headingright[0]?.text}</h2>
        {pageData.lower_textright[0]?.text}
      <div>
        <Button modifier="normal"
          className="Shapes__Button"
          link={'/get_in_touch'}
        >    Contact Us
        </Button>
      </div>
    </div>
    <div className='Shapes__Section_Tiles'>
      <ul>
      {lowerShapes.map((image, index) => {
        let shapeTitle

        if (lowerShapes[index]?.link !== undefined && lowerShapes[index]?.link.url !== null) {
          shapeTitle = <a target="_blank" href={lowerShapes[index]?.link.url}>
              <span className="shapes__link">{image.name[0]?.text}</span>
              <Picture className="shapes__link-icon" src={linkIcon}/>
            </a>
          
        } else {
          shapeTitle = 
            <span>{image.name[0]?.text}</span>
        }
        
        return (<li key={`img-${index}`}>  
        <Picture src={image.image.url} noDownload={true}/>
        <br />
        {shapeTitle}
      </li>)                
      })}
      </ul>
      </div>
  </div>
  
  const pageTitle = "Baguette terracotta Sunscreens | Shildan Group";
  const metaDescription = "Terracotta baguette helps create a dramatic, eco-friendly design for exterior facades and is available in an array of terracotta shapes, profiles, and finishes.";
  const metaKeywords = "Terracotta baguette, exterior facaed, terracotta shapes";

return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
      <Header currentPage="shapes" navigation={prismicNavigation} footer={prismicFooter} />

      <section className="ShapesContainer">

        <h1 className="Shapes__Title"
          data-is-on-screen>
           {pageData.heading[0]?.text}
        </h1>

        <div className='Shapes__Desc'>
        {pageData.heading_desc[0]?.text}
        </div>

        <div className='Shapes__Section'>
          <div className='Shapes__Section_TextRight'>
            <h2 className='Shapes__Section_Subheading'>{pageData.upper_headingright[0]?.text}</h2>
              {pageData.upper_textright[0]?.text}
            <div>
              <Button modifier="normal"
                className="Shapes__Button"
                link={'/get_in_touch'}
              >    Contact Us
              </Button>
            </div>
          </div>
          <div className='Shapes__Section_Tiles'>
            <ul>
              {upperShapes.map((image, index) => {
              
              let shapeTitle

              if (upperShapes[index]?.link !== undefined && upperShapes[index]?.link.url !== null) {
                shapeTitle = <a target="_blank" href={upperShapes[index]?.link.url}>
                    <span className="shapes__link">{image.name[0]?.text}</span>
                    <Picture className="shapes__link-icon" src={linkIcon}/>
                  </a>
                
              } else {
                shapeTitle = 
                  <span>{image.name[0]?.text}</span>
              }
              
                return (<li key={`img-${index}`}>  
                <Picture src={image.image.url} noDownload={true}/>
                <br />
                {shapeTitle}
              </li>)                
              })}
            </ul>
            </div>
        </div>

        <hr/>

        {lowerShapes.length > 0 && lowerSection}

        <br />
      </section>


      <Footer footer={prismicFooter.data} hasBorder={false} showImage={false} />
    </div>
  )
}


export const query = graphql`
	query BaguetteShapes {
    allPrismicShapes(filter: {uid: {eq: "baguetteshapes"}}) {
      edges {
        node {
          id
          data {
            heading {
              text
            }
            heading_desc {
              text
            }
            heading_banner_image {
              url
            }
            upper_textleft {
              text
            }
            upper_headingright {
              text
            }
            upper_textright {
              text
            }
            lower_textleft {
              text
            }
            lower_headingright {
              text
            }
            lower_textright {
              text
            }
            upper_shapes {
              image {
                url
              }
              name {
                text
              }
              link {
                url
              }
            }
            lower_shapes {
              image {
                url
              }
              name {
                text
              }
            }
          }
        }
      }
    }        
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`


export default Shapes
